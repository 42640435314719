import { useHead, useJsonld } from '#imports'
import type { SEO } from '@/utils/types'
import { useGlobalStore } from '~/stores'

export default function useSeo(seo: SEO) {
  const globalStore = useGlobalStore()

  const title = `${seo.meta_title || '讓生活大於想像'}｜${import.meta.env.VITE_WEBSITE_TITLE}`
  const description = seo.meta_description || globalStore.seoDescriptions.home

  useSeoMeta({
    title: title,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImage: seo.og_image?.link,
    keywords: seo.meta_keywords?.join(',')
  })

  useHead({
    link: [
      {
        rel: 'canonical',
        href: import.meta.env.VITE_DOMAIN
      }
    ]
  })

  useJsonld({
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: '首頁',
            item: import.meta.env.VITE_DOMAIN
          }
        ]
      }
    ]
  })
}
